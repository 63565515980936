<template>
  <svg-icon :data="sData" v-bind="$attrs" class="pkg-lineicons" />
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component
export default class IconBankAlt extends Vue {
  sData: string = require("@icon/lineicons/bank-alt.svg");
}
</script>
